<template>
  <error-report />
</template>

<script>
const ErrorReport = () => import('@/components/ErrorReport')

export default {
  name: 'ErrorReportView',
  components: {
    ErrorReport
  }
}
</script>
